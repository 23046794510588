<template>
    
  <div class="margin-page">

    <ExceptionModalOK
      ref="exceptionWarnigOK"
      tipo='error'
      :messageErr="internalizationAuth.authErrFetchLabel"
      :detailErr="internalizationAuth.authErrFetchMsg"
    />
    <ExceptionModal
      ref="exceptionWarnig"
      tipo='error'
      :messageErr="internalizationAuth.authErrValidationLabel"
      :detailErr="internalizationAuth.authErrValidationMsg"
    />

  </div>
    
</template>

<script>
import { getWithExpiry, setWithExpiry, getInternalization } from '@/util/util.js';

/* Internalization, used if not loaded with bd 
var internalizationAuthTmp = {

  "authErrValidationLabel": "Falha na Validação",
  "authErrValidationMsg": "Dados de autenticação não conferem com os informados na Iniciadora de Pagamentos. Inicie a solicitação de consentimento novamente.",

  "authErrFetchLabel": "Falha na Validação",
  "authErrFetchMsg": "Por favor, tente novamente mais tarde",

};
/* */

export default {
   
  data()  {
    return {
    }
  },
  methods:{
    showWarningModal(){
      this.$refs.exceptionWarnig.enable();
    },
  },
  created(){

    this.internalizationAuth = getInternalization();

    this.$root.$refs.mainNavbar.hideGoBackButton();
    this.$root.$refs.loadingModal.enable();
   
    const FETCH_TIMEOUT = 30000;
    let didTimeOut = false;

    const promise = new Promise(function(resolve, reject){

      const timeout = setTimeout(function() {
        didTimeOut = true;
        reject(new Error('Request timed out'));
      }, FETCH_TIMEOUT);

      let jwt;

      let cookiesTmp = document.cookie;

      if (!cookiesTmp.includes('JWE='))
        reject(new Error('Invalid Cookies'));

      cookiesTmp = cookiesTmp.split('JWE=')[1].trim();
      if (cookiesTmp.includes(' '))
        cookiesTmp = cookiesTmp.split(' ')[0];

      jwt = cookiesTmp;

      var urlReq = process.env.VUE_APP_CONSENTS+"/consent/auth/"+jwt;

      fetch(urlReq)
        .then(function(response) {

          clearTimeout(timeout);
          if(!didTimeOut) {
            resolve(response.text());
          }
        })
        .catch(function(err) {
            
          // Rejection already happened with setTimeout
          if(didTimeOut) return;
          // Reject with error
          reject(err);
          //console.log(err)
        });
    });

    promise.then((response)=> {
    
      const resp = JSON.parse(response);
      const forward = response;
      //sessionStorage.setItem('dados',forward)
      const expireIn = 300000;
      
      if(Object.prototype.hasOwnProperty.call(resp, "codError") || !Object.prototype.hasOwnProperty.call(JSON.parse(forward),"sub")){
        this.$root.$refs.loadingModal.disable();
        this.$refs.exceptionWarnig.enable();
      }
      //consent_id e o interaction_id
      else if(Object.prototype.hasOwnProperty.call(JSON.parse(forward),"consent_id" ) && Object.prototype.hasOwnProperty.call(JSON.parse(forward),"interaction_id" )){
        setWithExpiry('dados', forward, expireIn);
        this.$root.$refs.loadingModal.disable();
        this.$router.push({ name: 'ConfirmConsent' });
      }
      else{
        setWithExpiry('dados', forward, 0);
        this.$root.$refs.loadingModal.disable();
        this.$router.push({ name: 'MyConsents' });
      }

    })
    .catch((err) =>{
      // Error: response error, request timeout or runtime error
      this.$root.$refs.loadingModal.disable();
      this.$refs.exceptionWarnigOK.enable();
      //this.showWarningModal();
      console.log(err);
    });
  }
}
</script>

<style>

.margin-page{
  padding-left: 11px;
  padding-right: 18px;
  padding-top: 10px
}
.titlecss{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  padding-left: 13px;
  color: var(--tecban-text-black);
}
.separator{
  padding-bottom: 16px;
}

</style>          